import { UserGroup } from "../../graphql/graphql";

export enum UserGroups {
  CUSTOMER_NAME = "mappingCustomerName",
  TARGET_CUSTOMER_NAME = "mappingTargetCustomerName",
}

export const userGroups = Object.values(UserGroups);

export const userGroup: { [group: string]: string } = {
  [UserGroups.CUSTOMER_NAME]: "Customer",
  [UserGroups.TARGET_CUSTOMER_NAME]: "Target Customer",
};

export function filterMappingGroups(groups: UserGroup[]): string[] {
  return (
    groups?.flatMap((group) => {
      if (group.groupId == null) return [];
      const [firstPart] = group.groupId.split("/");
      return group.groupId.includes("/") &&
        userGroups.includes(firstPart as UserGroups)
        ? group.groupId
        : [];
    }) || []
  );
}
