import logo from './logo.png';

interface LogoProps {
  style?: React.CSSProperties;
}

export default function Logo({ style }: LogoProps) {
  return (
    <img src={logo} style={{ height:38, ...style }} />
  );
}
