import { useState } from "react";

export default function useDebounce(ms: number) {

  const [ _debounce, _setDebounce ] = useState(undefined as ReturnType<typeof setTimeout> | undefined);

  function setDebounce(fn: () => void) {
    _setDebounce(debounce => {
      if (debounce) clearTimeout(debounce);
      return setTimeout(() => fn(), ms);
    });
  }

  return setDebounce;
}