import { useEffect, useState } from 'react';

export default function useThrowError() {
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  return function throwError(error: Error) {
    setError(error);
  };
}
