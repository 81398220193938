import { Burger, Button, Flex, Group, Header, Text } from '@mantine/core';
import Logo from '../logo/logo';
import { IconChevronLeft } from '@tabler/icons-react';
import { useReferrer } from '../../app-state';

interface PageHeaderProps {
  title: string;
  opened: boolean;
  toggleNavbar: () => void;
}

export default function PageHeader({ title, opened, toggleNavbar }: PageHeaderProps) {

  const referrer = useReferrer();
  
  return (
    <Header height={{ base: 58, md: 58 }} p="xs">
      <Flex style={{ alignItems: 'center' }}>
        <Group style={{ flex: 1 }}>
          <Burger
            style={{ marginRight: 0 }}
            opened={opened}
            onClick={toggleNavbar}
            size="sm"
            mr="xl"
          />
          {referrer?.ref && <a href={referrer.ref}>
            <Button variant="outline" color="gray" leftIcon={<IconChevronLeft size={18} />}>
              Back{referrer.refname ? ` to ${referrer.refname}` : ''}
            </Button>
          </a>}

        </Group>

        <Group
          style={{ flex: 1, marginLeft: 32, marginRight: 32 }}
          position="center"
        >
          <Text weight={600}>{title}</Text>
        </Group>

        <Flex align="center" justify="flex-end"
              style={{ flex: 1, width: 216, columnGap: 8 }}>
          <Logo style={{ height: 30, paddingTop: '0.4rem' }} />
          <Text weight="600" color="#001e57">
            Data Mapping
          </Text>
        </Flex>
      </Flex>
    </Header>
  );
}
