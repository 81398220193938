import { ApolloClient, InMemoryCache, Operation, HttpLink } from '@apollo/client';
import { updateAuthorizationExpiration } from './identity';

const apiUrl = import.meta.env.VITE_DATA_MAPPING_API_URL + '/graphql';

const link = new HttpLink({
  uri: (operation: Operation) => apiUrl + '?op=' + operation.operationName,
  fetch: (uri, options) => {
    updateAuthorizationExpiration();

    return fetch(uri, options);
  },
  credentials: 'include'
});

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    }
  }
});
