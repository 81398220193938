import { MRT_ColumnDef } from "mantine-react-table";

export function fixColumnDefaults<T extends Record<string, any>>(columns: MRT_ColumnDef<T>[]) {
  for (let col of columns) {
    if (!col.size) col.size = 0;
    if (!col.filterFn) col.filterFn = 'includesString';
  }
}

// most tables work perfectly fine with the 'includesString' filter,
// but for the mapping table until custom filters are implemented
// we'll switch to startsWith
export function fixMappingColumnDefaults<T extends Record<string, any>>(columns: MRT_ColumnDef<T>[]) {
  for (let col of columns) {
    if (!col.size) col.size = 0;
    if (!col.filterFn) col.filterFn = 'includesString';
  }
}