import { useInterval } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { client } from './apollo';

const apiUrl = import.meta.env.VITE_DATA_MAPPING_API_URL;
const identityApiUrl = `${apiUrl}/api/identity`;
const authenticateUrl = `${identityApiUrl}/authenticate`;
const deauthenticateUrl = `${identityApiUrl}/deauthenticate`;
const inviteUrl = `${identityApiUrl}/invite`;
const reinviteUrl = `${identityApiUrl}/re-invite`;
const activateUrl = `${identityApiUrl}/activate`;
const recoverUrl = `${identityApiUrl}/recover`;
const resetUrl = `${identityApiUrl}/reset`;
const userUrl = `${identityApiUrl}/user`;
const usersUrl = `${identityApiUrl}/users`;

const AUTH_EXPIRATION_KEY = 'authExpiration';
const MAX_AGE = 120 * 60;
const REFRESH_AGE = 60;
const ABOUT_TO_EXPIRE_AGE = 60;

interface AuthenticateOptions {
  username: string;
  password: string;
}

export async function authenticate({
  username,
  password
}: AuthenticateOptions) {
  const body = {
    username,
    password,
    cookie: true
  };

  const { res, data } = await _post(authenticateUrl, body);

  authorize();
}

export async function deauthenticate() {
  deauthorize();

  await fetch(deauthenticateUrl, {
    method: 'POST',
    credentials: 'include'
  });
}

interface InviteOptions {
  email: string;
  fullName: string;
}

export async function invite({ email, fullName }: InviteOptions) {
  const body = {
    email,
    fullName
  };

  const { res, data } = await _post(inviteUrl, body);

  return data;
}

interface ReinviteOptions {
  email: string;
}

export async function reinvite({ email }: ReinviteOptions) {
  const body = {
    email
  };

  const { res, data } = await _post(reinviteUrl, body);

  return data;
}

interface GetActivateOptions {
  token: string;
}

export async function getActivate({ token }: GetActivateOptions) {
  const url = `${activateUrl}?token=${token}`;

  const { res, data } = await _get(url);

  return data;
}

interface ActivateOptions {
  token: string;
  fullName: string;
  password: string;
}

export async function activate({ token, fullName, password }: ActivateOptions) {
  const body = {
    token,
    fullName,
    password,
    cookie: true
  };

  const { res, data } = await _post(activateUrl, body);

  authorize();
}

interface RecoverOptions {
  email: string;
}

export async function recover({ email }: RecoverOptions) {
  const body = {
    email
  };

  const { res, data } = await _post(recoverUrl, body);

  return data;
}

interface GetResetOptions {
  token: string;
}

export async function getReset({ token }: GetResetOptions) {
  const url = `${resetUrl}?token=${token}`;

  const { res, data } = await _get(url);

  return data;
}

interface ResetOptions {
  token: string;
  password: string;
}

export async function reset({ token, password }: ResetOptions) {
  const body = {
    token,
    password,
    cookie: true
  };

  const { res, data } = await _post(resetUrl, body);

  authorize();
}

export async function getUser() {
  const { data } = await _get(userUrl);

  return data;
}

export async function getAllUsers() {
  const { data } = await _get(usersUrl);

  return data;
}

/**
 * Send a POST request to the specified URL with the provided body data.
 * @param {string} url - The URL to send the POST request to.
 * @param {*} body - The data to include in the request body.
 * @returns {Promise<{ res: Response, data: any }>} - A Promise that resolves with an object containing the Response object and the JSON data returned by the server.
 */
async function _post(url: string, body: any) {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body),
    credentials: 'include'
  });

  const data = await res.json();

  if (!res.ok) {
    if (data?.message === 'IncorrectUsernameOrPassword') {
      throw new IncorrectUsernameOrPasswordError();
    } else if (data?.message === 'UserExists') {
      throw new UserExistsError();
    } else if (data?.message === 'TokenNotValid') {
      throw new TokenNotValidError();
    } else if (data?.message === 'EmailNotValid') {
      throw new EmailNotValidError();
    } else {
      throw new Error(data?.message ?? res.statusText);
    }
  }

  return { res, data };
}

async function _get(url: string) {
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    },
    credentials: 'include'
  });

  const data = await res.json();

  if (!res.ok) {
    if (data?.message === 'TokenNotValid') {
      throw new TokenNotValidError();
    } else {
      throw new Error(data?.message ?? res.statusText);
    }
  }

  return { res, data };
}

function authorize() {
  updateAuthorizationExpiration(true);
}

function deauthorize() {
  removeAuthorizationExpiration();

  client.resetStore();
}

export function isAuthorized() {
  const authExpiration = getAuthorizationExpiration();

  return authExpiration != null && authExpiration > new Date();
}

function isAuthorizationAboutToExpire() {
  const authExpiration = getAuthorizationExpiration();

  return (
    authExpiration != null &&
    authExpiration < new Date(Date.now() + ABOUT_TO_EXPIRE_AGE * 1000)
  );
}

function isAuthorizationExpired() {
  const authExpiration = getAuthorizationExpiration();

  return authExpiration != null && authExpiration < new Date();
}

export function getAuthorizationExpiration() {
  const authExpiration = localStorage.getItem(AUTH_EXPIRATION_KEY);

  return authExpiration ? new Date(authExpiration) : null;
}

export function updateAuthorizationExpiration(authorize = false) {
  if (!isAuthorized() && !authorize) return;

  const authExpiration = new Date(Date.now() + (MAX_AGE - REFRESH_AGE) * 1000);

  localStorage.setItem(AUTH_EXPIRATION_KEY, authExpiration.toISOString());
}

function removeAuthorizationExpiration() {
  localStorage.removeItem(AUTH_EXPIRATION_KEY);
}

export function useAuthorizationAboutToExpire() {
  const [aboutToExpire, setAboutToExpire] = useState(false);

  const interval = useInterval(() => {
    setAboutToExpire(isAuthorizationAboutToExpire());
  }, 1000);

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  return aboutToExpire;
}

export function useAuthorizationExpired(callback: () => void) {
  const [expired, setExpired] = useState(false);

  const interval = useInterval(() => {
    if (isAuthorizationExpired()) {
      if (!expired) {
        callback();
        setExpired(true);
      }
    } else {
      setExpired(false);
    }
  }, 1000);

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);
}

export class IncorrectUsernameOrPasswordError extends Error {
  constructor() {
    super('Incorrect username or password');
  }
}

export class UserExistsError extends Error {
  constructor() {
    super('User already exists');
  }
}

export class TokenNotValidError extends Error {
  constructor() {
    super('Token not valid');
  }
}

export class EmailNotValidError extends Error {
  constructor() {
    super('Email not valid');
  }
}
