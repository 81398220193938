import { immerable } from "immer";
import { User as UserSchema, UserGroup, UserRole } from "../graphql/graphql";
import { UserRoles, userRoles } from "../pages/users/user-roles";
import { UserGroups, filterMappingGroups } from "../pages/users/user-groups";

export default class User implements UserSchema {
  [immerable] = true;

  id: string = "";
  username?: string;
  fullName?: string;
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  enabled: boolean = false;
  roles: UserRole[] = [];
  groups: UserGroup[] = [];
  createDate: Date = new Date();
  modifyDate: Date = new Date();

  constructor(user?: Partial<User>) {
    if (user != null) Object.assign(this, user);
  }

  isAMP(): boolean {
    return this.roles.map((r) => r.roleName).includes(UserRoles.AMP);
  }

  isAdmin(): boolean {
    return (
      this.isAMP() ||
      this.roles.map((r) => r.roleName).includes(UserRoles.ADMIN)
    );
  }

  getRole(): string {
    return (
      this.roles.find((role) => userRoles.includes(role.roleName as UserRoles))
        ?.roleName || ""
    );
  }

  getCustomer(): string {
    const customer: any = filterMappingGroups(this.groups).find((group) =>
      group.startsWith(UserGroups.CUSTOMER_NAME + "/"),
    );
    if (customer) return customer.split("/")[1];
    return "";
  }

  getTargetCustomer(): string {
    const targetCustomer: any = filterMappingGroups(this.groups).find((group) =>
      group.startsWith(UserGroups.TARGET_CUSTOMER_NAME + "/"),
    );
    if (targetCustomer) return targetCustomer.split("/")[1];
    return "";
  }
}
