import { Notification as MantineNotification } from '@mantine/core';

type NotificationProps = {
  notification: {
    notify: (message: string, title?: string, icon?: JSX.Element, ms?: number) => void;
    success: (message: string, title?: string, ms?: number) => void;
    error: (message: string, title?: string, ms?: number) => void;
    load: (message: string, title?: string, ms?: number) => void;
    show: boolean;
    loading: boolean;
    title: string | undefined;
    message: string;
    icon: JSX.Element | undefined;
    color: string | undefined;
  }
}

export default function Notification({
  notification
}: NotificationProps) {

  return (notification.show ?
    <MantineNotification style={{ position: 'fixed', zIndex: 999, top: '0.3rem', left: '50%', transform: 'translateX(-50%)' }}
        icon={notification.icon}
        title={notification.title}
        loading={notification.loading}
        color={notification.color}
        withCloseButton={!notification.loading}>
      {notification.message}
    </MantineNotification>
    : <></>
  );
}