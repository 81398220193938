import { useRouteError, isRouteErrorResponse, useNavigate } from 'react-router-dom';
import { Text, Container, Title, Group, Button, createStyles } from '@mantine/core';
import PageLayout from '../components/page-layout/page-layout';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80
  },

  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: Number(theme.spacing.xl) * 1.5,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[4]
        : theme.colors.gray[2],

    [theme.fn.smallerThan('sm')]: {
      fontSize: 120
    }
  },

  title: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32
    }
  },

  description: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: Number(theme.spacing.xl),
    marginBottom: Number(theme.spacing.xl) * 1.5
  }
}));

export default function ErrorPage() {
  const { classes } = useStyles();
  const error = useRouteError();
  const navigate = useNavigate();

  console.error(error);

  return (
    <PageLayout title="Error">
      <Container className={classes.root}>
        {/* <div className={classes.label}>404</div> */}
        <Title className={classes.title}>An error occurred.</Title>
        <Text
          color="dimmed"
          size="lg"
          align="center"
          className={classes.description}
        >
          {getErrorMessage(error)}
        </Text>
        <Group position="center">
          <Button variant="subtle" size="md" onClick={() => navigate(-1)}>
            Take me back to the previous page
          </Button>
        </Group>
      </Container>
    </PageLayout>
  );
}

function getErrorMessage(error: unknown) {
  if (isRouteErrorResponse(error)) {
    return error.statusText;
  }

  if (hasMessageString(error)) {
    return error.message;
  }

  return 'Unknown error';
}

interface MessageString {
  message: string;
}

function hasMessageString(obj: any): obj is MessageString {
  return (
    (obj as MessageString)?.message !== undefined &&
    typeof (obj as MessageString).message === 'string'
  );
}
