import { PropsWithChildren, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useLazyQuery } from '@apollo/client';
import useThrowError from './hooks/use-throw-error';
import { isAuthorized } from './identity';
import { gql } from './graphql/index';
import { createContext, useContext, useContextSelector } from 'use-context-selector';
import User from './model/User';

export const USER_FRAGMENT = gql(/* GraphQL */ `
  fragment CurrentUser on User {
    id
    username
    firstName
    lastName
    email
    enabled
    roles {
      userId
      roleName
    }
    groups {
      userId
      groupId
    }
    createDate
    modifyDate
  }
`);

export const GET_CURRENT_USER = gql(/* GraphQL */ `
  query GetCurrentUser {
    currentUser {
      ...CurrentUser
    }
  }
`);

export interface AppState {
  isAuthorized: boolean;
  currentUser?: User;
  referrer: { ref?: string, refname?: string; }
}

export const AppContext = createContext<AppState | null>(null);

export function useAppState() {
  return useContext(AppContext);
}

export function useIsAuthorized() {
  return useContextSelector(AppContext, (state: AppState | null) => state?.isAuthorized ?? false);
}

export function useCurrentUser() {
  return useContextSelector(AppContext, (state: AppState | null) => state?.currentUser);
}

export function useReferrer() {
  return useContextSelector(AppContext, (state: AppState | null) => state?.referrer);
}

const initialAppState: AppState = {
  isAuthorized: false,
  currentUser: undefined,
  referrer: {
    ref: undefined as string | undefined,
    refname: undefined as string | undefined
  }
};

export default function AppStateProvider({ children }: PropsWithChildren) {
  const [appState, setAppState] = useImmer<AppState>(initialAppState);
  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER);

  const throwError = useThrowError();

  useEffect(() => {
    //referrer
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referrer = urlParams.get('ref') || sessionStorage.getItem('ref');
    const referrerName = urlParams.get('refname') || sessionStorage.getItem('refname');

    if (referrer) sessionStorage.setItem('ref', referrer);
    if (referrerName) sessionStorage.setItem('refname', referrerName);

    //auth check
    if (!isAuthorized()) return;

    //set initial AppState
    setAppState((state) => {
      state.isAuthorized = true;
      if (referrer) {
        state.referrer.ref = referrer;
        if (referrerName) state.referrer.refname = referrerName;
      }
    });

    //grab the current user
    getCurrentUser()
      .then((result) => {
        if (result.data?.currentUser != null) {
          setAppState((state) => {
            state.currentUser = new User(result.data?.currentUser as User);
            if (state.currentUser?.isAdmin()) {
              localStorage.setItem('source_customerName', state.currentUser.getCustomer())
              localStorage.setItem('customerName', state.currentUser.getTargetCustomer())
            }
          });
        }
      })
      .catch((err) => {
        if (err?.name !== 'AbortError') {
          throwError(err);
        }
      });
  }, []);

  return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
}
