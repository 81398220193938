import { Button, Dialog, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useCheckForUpdate } from '../../update';

export default function CheckForUpdate() {
  const [opened, { open, close }] = useDisclosure(false);

  useCheckForUpdate(() => {
    open();
  });

  function handleReload() {
    window.location.reload();
  }

  return (
    <Dialog opened={opened} onClose={close} withCloseButton zIndex={999} pt={12}>
      <Text
        size="sm"
        mb="xs"
        weight="500"
        style={{
          borderBottom: '1px solid #88888844',
          paddingBottom: 10
        }}
      >
        Update Available
      </Text>
      <Text size="sm">
        A new version of the app is available. Reload the page to update.
      </Text>
      <Group position="center" mt="md">
        <Button variant="default" onClick={handleReload}>
          Reload &amp; Update
        </Button>
        <Button variant="filled" onClick={close}>
          Later
        </Button>
      </Group>
    </Dialog>
  );
}
