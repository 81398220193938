import { useEffect, useState } from 'react';
import { v5 as uuidv5 } from 'uuid';

const appUrl = '/';
const namespace = '59dae19a-e8f5-4f58-9158-8c63031809aa';
const CHECK_INTERVAL = 1000 * 60 * 1;

async function getVersion() {

  const data = await _get(appUrl);

  return uuidv5(data, namespace);
}

async function _get(url: string) {
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'text/html'
    },
    credentials: 'include'
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  const data = await res.text();

  return data;
}

export function useCheckForUpdate(callback: () => void) {
  if (window.location.hostname === 'localhost') return;

  const [version, setVersion] = useState<string>();

  useEffect(() => {
    if (version == null) {
      console.log(`${new Date().toISOString()} Getting current version...`);
      getVersion().then((currentVersion) => {
        setVersion((version) => currentVersion);
      });
    }

    const interval = setInterval(async () => {
      try {
        console.log(`${new Date().toISOString()} Checking for updates...`);

        const newVersion = await getVersion();

        setVersion((version) => newVersion);

        if (version != null && version !== newVersion) {
          console.log(`${new Date().toISOString()} Updated version found.`);
          callback();
        } else {
          console.log(`${new Date().toISOString()} No update found.`);
        }
      } catch (e) {
        console.error(e);
      }
    }, CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [version, callback]);

  return version;
}
