import { Loader } from "@mantine/core";

export default function PageLoader() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    }}>
      <Loader size="xl" />
    </div>
  );
}