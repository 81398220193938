import { gql } from "../graphql/index";


export const RETS_DATA_FRAGMENT = gql(/* GraphQL */ `
  fragment RetsData on RetsData {
    id
    sourceCustomerName
    sourceResourceName
    sourceClassName
    sourceBoardName
    sourceUniqueKey
    sourceListingKey
    sourceMlsStatus
    destinationCustomerName
    destinationListingKeyAssigned
    destinationDuplicatesListingKey
  }
`);

export const GET_RETS_DATA_PAGE = gql(/* GraphQL */ `
  query GetRetsDataPage(
    $customerName: String
    $search: String
    $page: Int
    $size: Int
    $sort: String
    $direction: SortDirection
    $filters: [FilterInput]
  ) {
    retsDataPage(
      customerName: $customerName
      search: $search
      page: $page
      size: $size
      sort: $sort
      direction: $direction
      filters: $filters
    ) {
      id
      page {
        ...RetsData
      }
      count
      hasPreviousPage
      hasNextPage
    }
  }
`);

export const GET_RETS_DATA_CUSTOMERS = gql(/* GraphQL */ `
  query GetRetsDataCustomers {
    retsDataCustomers
  }
`);