import { MantineThemeOverride } from '@mantine/core';

const themeOverride: MantineThemeOverride = {
  components: {
    Select: {
      styles: {
        dropdown: {
          marginTop: -3
        }
      }
    },

    TextInput: {
      styles: {
        input: { padding: '1px 8px' }
      }
    },

    InputWrapper: {
      styles: {
        label: {
          marginBottom: '4px'
        }
      }
    },

    Modal: {
      styles: {
        header: {
          marginTop: -8,
          marginRight: 0,
          paddingBottom: 8,
          borderBottom: '1px solid #88888844'
        },
        close: {
          marginRight: -8
        },
        title: {
          fontWeight: 600
        }
      }
    },

    Dialog: {
      styles: {
        header: {
          paddingBottom: 6,
          borderBottom: '1px solid #88888844'
        }
      }
    },

    ActionIcon: {
      defaultProps: {
        size: 'lg'
      },
      styles: {
        root: {
          color: '#000'
          // '&:hover': {
          //   backgroundColor: '#88888822'
          // }
        }
      }
    },

    Tooltip: {
      defaultProps: {
        openDelay: 1250,
        closeDelay: 250,
        transition: 'fade'
      }
    }
  }
};

export default themeOverride;
