import { IconCheck, IconX } from "@tabler/icons-react";
import { useState } from "react";

export default function useNotification() {

  const [ show, setShow ] = useState(false);
  const [ title, setTitle ] = useState(undefined as string | undefined);
  const [ color, setColor ] = useState(undefined as string | undefined);
  const [ message, setMessage ] = useState('');
  const [ icon, setIcon ] = useState(undefined as JSX.Element | undefined);
  const [ loading, setLoading ] = useState(false);
  const [ _timeout, _setTimeout ] = useState(undefined as ReturnType<typeof setTimeout> | undefined);

  function notify(message: string, title?: string, icon?: JSX.Element, ms?: number) {
    setMessage(message);
    setTitle(title || '');
    setIcon(icon || undefined);
    setColor(undefined);
    setLoading(false);
    setShow(true);
    if (_timeout) clearTimeout(_timeout);
    setTimeout(() => setShow(false), ms || 5000);
  }

  function success(message: string, title?: string, ms?: number) {
    setMessage(message);
    setTitle(title || undefined);
    setIcon(<IconCheck size="1.1rem" color="green" />);
    setColor('green');
    setLoading(false);
    setShow(true);
    if (_timeout) clearTimeout(_timeout);
    setTimeout(() => setShow(false), ms || 5000);
  }

  function error(message: string, title?: string, ms?: number) {
    setMessage(message);
    setTitle(title || undefined);
    setIcon(<IconX size="1.1rem" color="red" />);
    setColor('red');
    setLoading(false);
    setShow(true);
    if (_timeout) clearTimeout(_timeout);
    setTimeout(() => setShow(false), ms || 5000);
  }

  function load(message: string, title?: string, ms?: number) {
    setMessage(message);
    setTitle(title || undefined);
    setIcon(undefined);
    setColor(undefined);
    setLoading(true);
    setShow(true);
    if (_timeout) clearTimeout(_timeout);
    if (ms) _setTimeout(setTimeout(() => { setShow(false); setLoading(false) }, ms));
  }

  function dismiss() {
    setShow(false);
    setLoading(false);
    if (_timeout) clearTimeout(_timeout);
  }

  return {
    notify, success, error, load, dismiss,
    show,
    loading,
    title,
    message,
    icon,
    color,
  };
}