import { UserRole } from "../../graphql/graphql";

export enum UserRoles {
  AMP = 'MAPPING_AMP',
  ADMIN = 'MAPPING_ADMIN',
  READONLY = 'MAPPING_READONLY'
}

export const userRoles = Object.values(UserRoles);

export const userRole: { [role: string]: string } = {
  [UserRoles.AMP]: 'AMP',
  [UserRoles.ADMIN]: 'Admin',
  [UserRoles.READONLY]: 'Read-Only',
};

export function filterMappingRoles(roles: UserRole[]): string[] {
  return <string[]>roles?.filter(role => (<string[]>userRoles).includes(role.roleName as string)).map(r => r.roleName);
}