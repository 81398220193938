import { AppShell } from '@mantine/core';
import { PropsWithChildren } from 'react';
import PageHeader from './page-header';
import { useDebouncedState, useDocumentTitle } from '@mantine/hooks';
import AppNavbar from '../app-navbar/app-navbar';

interface PageLayoutProps extends PropsWithChildren {
  title: string;
}

export default function PageLayout({ title, children }: PageLayoutProps) {
  
  useDocumentTitle(title + ' - Amplify Data Mapping');

  const [ opened, setOpened ] = useDebouncedState(false, 150);
  function toggle() { setOpened(!opened) }

  return (
    <AppShell navbar={<AppNavbar opened={opened} setOpened={setOpened} />} header={<PageHeader title={title} opened={opened} toggleNavbar={toggle} />} fixed={true} styles={{
      root: {
        height: '100vh !important',
        overflow: 'hidden !important',
      },
      body: {
        height: 'calc(100% - var(--mantine-header-height)) !important',
        overflow: 'hidden !important',
        marginTop: 'var(--mantine-header-height) !important'
      },
      main: {
        minHeight: '100% !important',
        height: '100% !important',
        maxHeight: '100% !important',
        padding: '0 !important',
        overflow: 'auto !important'
      }
    }}>
      {children}
    </AppShell>
  );
}
