export const mantinePaperProps: any = {
  sx: {
    height: '100% !important',
    width: '100% !important',
    position: 'relative',
    display: 'flex !important',
    flexDirection: 'column',
  }
}

export const mantineTableContainerProps: any = {
  sx: {
    overflow: 'auto !important'
  }
}

export const mantineTableHeadProps: any = {
  sx: {
    zIndex: 0
  }
}

export const mantinePaginationProps: any = {
  rowsPerPageOptions: [ '50', '100', '150', '200', '300', '500' ]
}

export const mantinePaginationFilterProps: any = {
  withEdges: false,
  rowsPerPageOptions: [ '50', '100', '150', '200', '300', '500' ]
}