import { Divider, Navbar, Menu } from '@mantine/core';
import { useClickOutside, useEventListener, useMergedRef } from '@mantine/hooks';
import {
  Icon,
  IconBoxAlignTop,
  IconBoxSeam,
  IconBrandGraphql,
  IconBug,
  IconFileSearch,
  IconHome,
  IconListSearch,
  IconLogout,
  IconMap,
  IconRuler2,
  IconRulerMeasure,
  IconUserCircle,
  IconUsersGroup,
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';

interface AppLinkProps {
  icon: Icon;
  label: string;
  link: string;
}

function NavbarLink({ icon: Icon, label, link }: AppLinkProps) {
  return (
    <Menu.Item component={Link} to={link} icon={<Icon stroke={1.5} />} style={{ textDecoration: 'none', background: 'inherit' }}>
      {label}
    </Menu.Item>
  );
}

const homeLink: AppLinkProps = { icon: IconHome, label: 'Home', link: '/home' };
const mappingLinks: AppLinkProps[] = [
  { icon: IconUsersGroup, label: 'Customers', link: '/customers' },
  { icon: IconMap, label: 'Mappings', link: '/mappings' },
  { icon: IconRuler2, label: 'Mapping Rules', link: '/mappingrules' },
  { icon: IconRulerMeasure, label: 'Rule Tool', link: '/ruletool' }
];
const metadataLinks: AppLinkProps[] = [
  { icon: IconBoxSeam, label: 'Resources', link: '/resources' },
  { icon: IconBoxAlignTop, label: 'Fields', link: '/fields' },
  { icon: IconListSearch, label: 'Lookups', link: '/lookups' }
];
const retsLinks: AppLinkProps[] = [
  { icon: IconBoxAlignTop, label: 'Source Fields', link: '/retsfields' },
  { icon: IconListSearch, label: 'Source Lookups', link: '/retslookups' },
  { icon: IconFileSearch, label: 'Source Media', link: '/retsmedias' },
  { icon: IconFileSearch, label: 'Source Data', link: '/retsdata' }
];
const miscLinks: AppLinkProps[] = [
  { icon: IconUserCircle, label: 'Users', link: '/users' },
  { icon: IconBug, label: 'Diagnostic Info', link: '/debug' },
  { icon: IconBrandGraphql, label: 'GraphiQL', link: '/graphiql' },
  { icon: IconLogout, label: 'Sign Out', link: '/signout' },
];

interface AppNavbarProps {
  opened: boolean;
  setOpened: Function;
}

export default function AppNavbar({ opened, setOpened }: AppNavbarProps) {
  const clickOutsideRef = useClickOutside(() => setOpened(false));
  const clickInsideRef = useEventListener('click', () => setOpened(false));
  const combinedRef = useMergedRef(clickInsideRef, clickOutsideRef);
  
  return (<Navbar w={240} style={{ display: opened ? 'initial' : 'none' }} ref={combinedRef}><Menu>
    <NavbarLink icon={homeLink.icon} label={homeLink.label} link={homeLink.link} />

    <Divider label="Mapping" labelPosition="center" />
    {mappingLinks.map(link => <NavbarLink key={link.link} icon={link.icon} label={link.label} link={link.link} />)}

    <Divider label="Metadata" labelPosition="center" />
    {metadataLinks.map(link => <NavbarLink key={link.link} icon={link.icon} label={link.label} link={link.link} />)}

    <Divider label="RETS Data" labelPosition="center" />
    {retsLinks.map(link => <NavbarLink key={link.link} icon={link.icon} label={link.label} link={link.link} />)}

    <Divider />{/* misc */}
    {miscLinks.map(link => <NavbarLink key={link.link} icon={link.icon} label={link.label} link={link.link} />)}

  </Menu></Navbar>);
}